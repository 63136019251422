import React from 'react';

const IconLogo = () => (
  <svg
    width="138"
    height="138"
    viewBox="0 0 138 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path d="M69 24L30 46V91L69 114L108 92V47L69 24Z" stroke="currentColor" />
        <path
          d="M58.7976 84V55.6364H69.4339C71.6129 55.6364 73.441 56.0149 74.9183 56.772C76.4048 57.5291 77.5266 58.5909 78.2837 59.9574C79.0501 61.3146 79.4332 62.8981 79.4332 64.7077C79.4332 66.5266 79.0454 68.1055 78.2699 69.4442C77.5035 70.7738 76.3725 71.8033 74.8768 72.5327C73.381 73.2528 71.5437 73.6129 69.3647 73.6129H61.7891V69.3473H68.6722C69.9464 69.3473 70.9897 69.1719 71.8022 68.821C72.6147 68.4609 73.2148 67.9393 73.6026 67.256C73.9996 66.5636 74.1981 65.7141 74.1981 64.7077C74.1981 63.7013 73.9996 62.8427 73.6026 62.1317C73.2056 61.4116 72.6008 60.8668 71.7883 60.4975C70.9758 60.119 69.9279 59.9297 68.6445 59.9297H63.9357V84H58.7976ZM73.4503 71.1477L80.4719 84H74.7383L67.8413 71.1477H73.4503Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
